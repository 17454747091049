import React from 'react';
import './exam.css';
import hljs from 'highlight.js/lib/highlight';
import javascript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/github.css';

import leftarrow from '../../assets/leftarrow.svg';

class Exam extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            question: props.question,
            style: props.style,
            section: props.section,
            activeQ: 0
        };
        this.tries = 0;
    }

    componentDidMount() {
        hljs.registerLanguage('javascript', javascript);
        hljs.initHighlightingOnLoad();
        document.querySelectorAll("pre code").forEach(block => {
            hljs.highlightBlock(block);
        });
        let active = document.getElementById('actives');
        if (active) {
            active.classList.add('openexam');
            active.scrollIntoView({behavior: 'smooth'});
        }
    }

    componentDidUpdate() {
        document.querySelectorAll("pre code").forEach(block => {
            hljs.highlightBlock(block);
        });
    }

    render() {
        return (
            <div id='actives' style={{background: this.props.bgcolor}}>
                <img src={leftarrow} alt="back" onClick={() => { this.closeExam() }} className="backarrow"/> 
                <h1 className="title">{this.state.section.title}</h1>
                <p className="subtitle">{this.state.section.subtitle}</p>
                <div className="questions">
                    {
                        <div className="question">
                            <div className="question-inner-container">
                                {this.getQuestionStateMsg()}
                                <p className="question-number">Question {this.state.activeQ + 1}</p>
                                <p className="question-title">{this.state.question.title}</p>
                                <div className="question-content">
                                    <pre><code className="javascript">{this.state.question.content}</code></pre>
                                </div>
                                <div className="question-options">
                                    {this.getQuestionOptions(this.state.question.options, this.state.activeQ)}
                                </div>
                                {this.getActions()}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    closeExam() {
        let active = document.getElementById('actives');
        active.classList.remove('openexam');
        active.classList.add('slideout');
        this.props.close(this.state.section, this.tries);
    }

    getQuestionOptions(options, qIndex) {
        let keys = Object.keys(options);
        return keys.map(key => {
            return <label key={key}>
                <input  type="radio"
                        value={key}
                        style={{marginRight: '10px'}}
                        disabled={this.forceNextQ}
                        checked={this.state.section.userAns[qIndex] === key}
                        onChange={(e) => this.handleOptionChange(qIndex, key)}/>
                {options[key]}
            </label>;
        });
    }

    getQuestionStateMsg() {
        let res = this.checkAns();
        switch (typeof(res)) {
            case 'boolean': {
                return <div className="wrong-msg">Incorrect answer. Please try again.</div>;
            }
            case 'string': {
                this.forceNextQ = true;
                return <div className="success-msg">Great! {res}</div>
            }
            default: return <div style={{display: 'none'}}></div>
        }
      }
    
    checkAns(setStatus) {
        let section = this.state.section;
        let question = this.state.question;
        let questionIndex = this.state.activeQ;
        let userAns = section.userAns[questionIndex];
        
        if (setStatus) {
            this.tries += 1;
            question.solutionStatus = true;
            this.setState({
                question: question
            });
        }
        if (userAns && question.solutionStatus) {
            if (question.correctAns + '' !== userAns + '') return false;
            return question.description;
        } else {
            return null;
        }
    }    
    
    handleOptionChange(questionIndex, ansIndex) {
        let section = {...this.state.section};
        section.userAns[questionIndex] = ansIndex;
        section.questions[questionIndex].solutionStatus = false;
        this.setState({
            section: section
        });
    }

    getActions() {
        if (!this.forceNextQ) {
            return (
                <div className="actions">                    
                    <button className="skip" disabled={this.state.section.questions.length - 1 === this.state.activeQ } onClick={this.nextQ.bind(this)}>Skip question</button>
                    <button className="check" disabled={!this.state.section.userAns[this.state.activeQ]} onClick={this.checkAns.bind(this, true)}>Check answer</button>
                </div>
            );
        } else {
            if (this.state.section.questions.length - 1 === this.state.activeQ) {
                return (
                    <div className="actions">
                        <button className="nextq" onClick={this.closeExam.bind(this)}>Done!</button>
                    </div>
                );
            } else {
                return (
                    <div className="actions">                    
                        <button className="nextq" onClick={this.nextQ.bind(this)}>Next!</button>
                    </div>
                );
            }
        }
    }

    nextQ() {
        this.forceNextQ = false;
        let section = this.state.section;
        let activeQ = this.state.activeQ;
        if (activeQ + 1 < section.questions.length) {
            let newq = this.state.section.questions[activeQ + 1];
            this.setState({
                activeQ: activeQ + 1,
                question: newq
            });
        }
    }
}

export default Exam;