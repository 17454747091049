const sections = [
    {
        title: 'Basics',
        subtitle: 'use strict? pass by reference or by value',
        questions: [
            {
                title: 'What this code will print out?',
                content:
                `"use strict";
var a = 1;
var b = {};
function f(a, b) {
    b.par = 1;
    a = 3;
}
                
f(a, b);
console.log('a: ' + a + ' - b: ' + JSON.stringify(b));`, 
                options: {
                    1: 'a: 1 - b: {par : 1}',
                    2: 'a: 3 - b: {par : 1}',
                    3: 'a: 3 - b: {}',
                    4: 'a: 1 - b: {}'
                },
                correctAns: 1,
                description: `The variable a is passed by value, so updates to variable 'a' in the function will not be visible outside of the function.`
            },
            {
                title: 'What this code will print out?',
                content: 
                `var abcd = 3;
abdd = 2;
console.log(abdd);`, 
                options: {
                    1: '3',
                    2: '2',
                    3: 'undefined',
                    4: 'It throws an error'
                },
                correctAns: 2,
                description: `Because we are in non-strict mode so using a variable that hasn't been defined (abdd) will decalre a global variable called abdd.`
            },
            {
                title: 'What this code will print out?',
                content: 
                `"use strict"
var abcd = 3;
abdd = 2;
console.log(abdd);`, 
                options: {
                    1: '3',
                    2: '2',
                    3: 'undefined',
                    4: 'It throws an error'
                },
                correctAns: 4,
                description: `Because we are in strict mode this code will throw an error because we are using a variable that we havn't declared.`
            }
        ]
    },
    {
        title: 'Types and equality',
        subtitle: '== OR ===? typeof(null)? NaN?',
        questions: [
            {
                title: 'undefined or null',
                content:
                `if (undefined == null)
    console.log('YES');
else
    console.log('NO');`,
                options: {
                    1: 'YES',
                    2: 'NO',
                    3: 'It throws an error'
                },
                correctAns: 1,
                description: `Because we are checking just for 'value' equality so they are both equal.`
            },
            {
                title: 'typeof null',
                content: `console.log('typeof(null));`,
                options: {
                    1: 'null',
                    2: 'NULL',
                    3: 'object',
                    4: 'Will throw an error'
                },
                correctAns: 3,
                description: `There is a bug in JS and it incorrectly reports that typeof null is object, and it is too late to fix it, so they keep it as is.`
            },
            {
                title: 'undefined or null',
                content: `if (undefined === null)
    console.log('YES');
else
    console.log('NO');`,
                options: {
                    1: 'YES',
                    2: 'NO',
                    3: 'It throws an error'
                },
                correctAns: 2,
                description: `We are checking for types equality so they are not the same type`
            },
            {
                title: 'NaN',
                content: `console.log(NaN == '2')`,
                options: {
                    1: 'false',
                    2: 'true',
                    3: 'It throws an error'
                },
                correctAns: 1,
                description: `NaN equal to anything is always false even when compared to itself!`
            },
            {
                title: 'Best way to check for NaN',
                content: `How to check if variable 'a' is NaN ?`,
                options: {
                    1: 'a == NaN',
                    2: 'a === NaN',
                    3: 'a !== a'
                },
                correctAns: 3,
                description: `variable !== variable will always return false except for NaN. it will return true when the variable is NaN`
            }
        ]
    },
    {
        title: 'Scopes',
        subtitle: 'Global scope? function scope? IIFE? variable hoisting? function closure?',
        questions: [
            {
                title: 'What this code will print out?',
                content: `"use strict"
var name = 'First';
var code = "var name = 'Test this one';"
eval(code);
console.log(name);`,
                options: {
                    1: 'First',
                    2: 'Test this one',
                    3: 'undefined'
                },
                correctAns: 1,
                description: `Since we are in use strict mode the effects are not leaked out of the eval block.`
            },
            {
                title: 'What this code will print out?',
                content:
`(function() {
    var a = 'something';
})();
console.log(typeof(a) !== 'undefined');`,
                options: {
                    1: 'true',
                    2: 'false',
                    3: 'undefined'
                },
                correctAns: 3,
                description: `'a' is declared inside a function so it isn't available outside the function and its value is undefined.`
            },
            {
                title: 'What this code will print out?',
                content: `console.log(me);
var me = function() {
    console.log('my value');
};`,
                options: {
                    1: 'my value',
                    2: 'undefined',
                    3: 'It throws an error'
                },
                correctAns: 2,
                description: `Here variable hoisting will move the var me to the top of its scope, and since its just declared and not initialized during the console.log so its value will be undefined.`
            },
            {
                title: 'What the event listener will print out?',
                content: `for (var i = 0; i < 10; i ++) {
    var btn = document.createElement('button');
    btn.appendChild(document.createTextNode('Clicked ' + i));
    btn.addEventListener('click', function() { console.log(i); });
    document.body.appendChild(btn);
}`,
                options: {
                    1: '10',
                    2: '0',
                    3: 'i'
                },
                correctAns: 3,
                description: `By the time the function in the event listener is called, event it kept a reference to the variable i in its closure, the i variable at this point is actually 10.`
            },
            {
                title: 'IIFE, what this code will print out?',
                content: `(function(arg1) {
    return (function(arg2) {
        console.log(arg1);
    })(2);
})(1);`,
                options: {
                    1: '1',
                    2: '2',
                    3: 'undefined'
                },
                correctAns: 1,
                description: `javascript will search the function outer scope and it will find arg1 in there and its value is 1;`
            },
            {
                title: 'Scope chain, what this code will print out?',
                content: `function f1() {
    var a = 1;
    f2();
}

function f2() {
    console.log(a);
}

f1();`,
                options: {
                    1: 'undefined',
                    2: 'Uncaught ReferenceError: a is not defined.',
                    3: '1'
                },
                correctAns: 2,
                description: `The scope chain lookup rules follow how the code is written. so the f2 function doesn't see the a variable either in its local or outer global scope so the javascript engine will throw an error.`
            },
            {
                title: 'IIFE, what this code will print out?',
                content: `var a = '10x';
(function() {
    console.log('Original value was: ' + a);
    var a = '20x';
    console.log('New value is: ' + a);
})();`,
                options: {
                    1: 'Original value was 10x',
                    2: 'Original value was 20x',
                    3: 'Original value was undefined'
                },
                correctAns: 3,
                description: `The variable a in the IIFE is hoisted to the top of the function scope, so that means that the variable is set to undefined by the time the first console log is run.`
            }
        ]
    },
    {
        title: 'Object orientation',
        subtitle: 'Constructor OO pattern? prototype?',
        questions: [
            {
                title: 'How would you create an instance of this pseudo-class using the constructor pattern?',
                content : 
`function Device(kind) {
    this.kind =  kind;
}`,
                options: {
                    1: `var product = new Device('music player');`,
                    2: `var product = Device('music player');`,
                    3: `var product = Device.call('music player');`
                },
                correctAns: 1,
                description: 'The new keyword is used with a function constructor to generate an instance of a pseudo-class.'
            },
            {
                title: 'What would the below code print out?',
                content : 
`function Device(kind) {
    this.kind =  kind;
    this.printKind = function () {
        console.log(this.kind)
    }
}
var product = new Device("iPhoneX");
product.printKind();
`,
                options: {
                    1: 'iPhoneX',
                    2: 'undefined',
                    3: 'It throws an error'
                },
                correctAns: 1,
                description: 'this keyword will always point to the calling context, in this case the caller is product and the kind is iPhoneX'
            },
            {
                title: 'What would the below code print out?',
                content : 
`function Device(kind) {
    this.kind =  kind;
    this.printKind = function () {
        console.log(kind)
    }
}
var product = new Device("iPhoneX");
product.kind = 'iPhone 6';
product.printKind();
`,
                options: {
                    1: 'iPhoneX',
                    2: 'undefined',
                    3: 'It throws an error',
                    4: 'iPhone 6'
                },
                correctAns: 1,
                description: 'In this case the printKind function is a closure and it refers to the kind argument passed into the function constructor and not the this.kind'
            },
            {
                title: 'What would the below code print out?',
                content : 
`function Device(kind) {
    this.kind =  kind;
}
Device.prototype.printKind = function () {
    console.log(this.kind);
};
var product = new Device("iPhoneX");
product.printKind();
`,
                options: {
                    1: 'iPhoneX',
                    2: 'undefined',
                    3: 'It throws an error'
                },
                correctAns: 1,
                description: 'javascript will lookup for printKind and will finds it in the prototype of the product object'
            },
            {
                title: 'How to let AppleProduct inherit from Device?',
                content : 
`function Device(kind) {
    this.kind =  kind;
}
function AppleProduct(name, kind) {
    this.name = name;
}
`,
                options: {
                    1: `
                    function AppleProduct(name, kind) {
                        this.name = name;
                        this.kind = kind;
                    }
                    `,
                    2: `
                    function AppleProduct(name, kind) {
                        this.name = name;
                        Device.call(this, kind);
                    }`,
                    3: `
                    function AppleProduct(name, kind) {
                        this.name = name;
                        Device(kind);
                    }`
                },
                correctAns: 2,
                description: '"re-use" the Device function constructor'
            },
            {
                title: 'How to let AppleProduct inherit from Device? and How do we make available to AppleProduct the functions created for Device?',
                content : 
`function Device(kind) {
    this.kind =  kind;
}
Device.prototype.printKind = function () {
    console.log(this.kind);
};
function AppleProduct(name, kind) {
    this.name = name;
    Device.call(this, kind);
}
`,
                options: {
                    1: `AppleProduct.prototype = Object.create(Device.prototype);`,
                    2: `AppleProduct.prototype.__proto__ = Device.prototype;`,
                    3: `Nothing. the functions are already available.`
                },
                correctAns: 1,
                description: ''
            },
            {
                title: 'What the code below will print out?',
                content : 
`var Device = {
    kind:"Music Player"
};
var AppleProduct = Object.create(Device,
{
    name: "iPod"
});
var purchase = Object.create(AppleProduct);
console.log(purchase.name);
`,
                options: {
                    1: `undefined`,
                    2: `iPod`,
                    3: 'Uncaught TypeError: Property description must be an object: iPod'
                },
                correctAns: 3,
                description: `The second param to the Object.create function is an object literal that described the properties we want to create on the object. So the value should be something like {name: { value: 'iPod' }} and not {name:'iPod'}`
            }
        ]
    },
    {
        title: 'Networking',
        subtitle: 'CORS',
        questions: [
            {
                title: 'What does CORS stand for?',
                content : ``,
                options: {
                    1: `Cross Origin Resource Sending`,
                    2: `Cross Origin Resource Sharing`,
                    3: `Cross Origin Request Sending`
                },
                correctAns: 2,
                description: 'No need to describe anything (:'
            },
            {
                title: 'What header is always sent by the browser on a CORS request?',
                content : ``,
                options: {
                    1: `Origin`,
                    2: `Access-Control-Allow-Origin`,
                    3: `Authorization`
                },
                correctAns: 1,
                description: 'The Origin header will always be on a cross origin request'
            },
            {
                title: 'What header needs the be present on a CORS response to a GET request for the browser to allow a request to pass through?',
                content : ``,
                options: {
                    1: `Access-Control-Allow-Origin`,
                    2: `Origin`,
                    3: `Authorization`
                },
                correctAns: 1,
                description: 'If the Access-Control-Allow-Origin header is not in the response the browser will block it.'
            },
            {
                title: 'If we were making a POST CORS request, what HTTP request does the browser send first?',
                content : ``,
                options: {
                    1: `POST`,
                    2: `GET`,
                    3: `OPTIONS`
                },
                correctAns: 3,
                description: `The browser sends what's called a "pre-flight" options request first to check to see if it can send a POST request later on.`
            },
            {
                title: 'When we make a POST CORS request, the response to the pre-flight request needs to contain a header with a specific value.',
                content : `Which of the below options would be an acceptable response for the browser to allow the POST request?`,
                options: {
                    1: `Access-Control-Require-Method: POST`,
                    2: `Access-Control-Require-Method: PUT`,
                    3: `Access-Control-Require-Method: POST, PUT, GET, DELETE`,
                    4: `Access-Control-Request-Method: POST, PUT, GET, DELETE`
                },
                correctAns: 4,
                description: ``
            },
        ]
    },
    {
        title: 'Events',
        subtitle: 'Event capturing or bubbling? prevent default? stopPropogation?',
        questions: [
            {
                title: 'Does event capturing happen first or is it event bubbling?',
                content : ``,
                options: {
                    1: `Event capturing happens first`,
                    2: `Event bubbling happens first`
                },
                correctAns: 1,
                description: 'Event capturing phase runs at first from top to bottom then there is a phase from bottom up for event bubbling'
            },
            {
                title: 'Does this code add an event listener to the event capturing phase or the bubbling phase?',
                content : 
`(function () {
    var y = i;
    items[y].addEventListener("click",
        function (event) {
            console.log(items[y], event);
        }, true);
})();`,
                options: {
                    1: `Event capturing phase`,
                    2: `Event bubbling phase`
                },
                correctAns: 1,
                description: 'The third argument to the event listener function will decides on which phase we want the event to run.'
            },
            {
                title: 'Calling the prevent default function in an event listener stops the next event listener from being called?',
                content : ``,
                options: {
                    1: 'True',
                    2: 'False'
                },
                correctAns: 2,
                description: `The prevent default won't stop the event from propagating instead it will prevent its default behaviour.`
            },
            {
                title: 'If we clicked a checkbox, calling preventDefault() from one of the event listeners would stop the checkbox from having the tick applied to it.',
                content : ``,
                options: {
                    1: 'True',
                    2: 'False'
                },
                correctAns: 1,
                description: `Prevent default stops the default action of an element, but doesn't stop the event from propagating`
            }
        ]
    }
];

export default sections;