import React, { Component } from 'react';
import './App.css';

import Main from './components/main/main';


class App extends Component {

  componentDidMount() {
    let loader = document.getElementById('loader');
    loader.classList.add('fadeOut');
    setTimeout(() => {
        loader.parentNode.removeChild(loader);
    }, 500);
  }

  render() {
    return (
      <div className="App">
        <Main></Main>
      </div>
    );
  }
}

export default App;
