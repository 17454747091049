import React, { Component } from 'react';
import './main.css';

import jssvg from '../../assets/jssvg.svg';
import sections from '../../assets/questions';
import { colors } from '../../assets/colors';

import Tile from '../tile/tile';
import Exam from '../exam/exam';


class Main extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        sections: sections.map(section => {
            section['userAns'] = {};
            return section;
        }),
        activeSection: {
            status: false,
            left: null,
            top: null,
            index: null,
            activeQ: null
        }
    };
    this.forceNextQ = false;
    this.tries = 0;
  }

  handleSectionClick(index, event) {
    this.setState({
        activeSection: {
            status: true,
            left: event.clientX,
            top: event.clientY,
            index: index,
            activeQ: 0
        }
    });
  }

  getSections() {
    return sections.map((section, index) => {
        return <Tile key={index} index={index} color={colors[index]} clickEv={this.handleSectionClick.bind(this)} numberOfCorrentAns={this.getNumberOfCorrectAns(section)} total={section.questions.length} title={section.title}></Tile>
    });
  }

  getNumberOfCorrectAns(section) {
    let i = 0;
    let res = 0;
    for (let q of section.questions) {
        if (q.correctAns + '' === section.userAns[i] + '') res += 1;
        i += 1;
    }
    return res + '';
  }

  getResult() {
    let sections = this.state.sections;
    let total = 0;
    let res = 0;
    for (let section of sections) {
        let i = 0;
        total += section.questions.length;
        for (let q of section.questions) {
            if (q.correctAns + '' === section.userAns[i] + '') res += 1;
            i += 1;
        }
    }
    return `${res} / ${total}`;
  }

  getUpperSection() {
    if (this.state.activeSection.status) {
        let section = this.state.sections[this.state.activeSection.index];
        let question = section.questions[this.state.activeSection.activeQ];
        let bgcolor = colors[this.state.activeSection.index];
        return <Exam question={question} section={section} bgcolor={bgcolor} close={this.closeExam.bind(this)}></Exam>
    }
    return <div style={{display: 'none'}}></div>;
  }

  closeExam() {
    let sections = [...this.state.sections];
    sections[this.state.activeSection.index] = arguments[0];
    this.tries += arguments[1];
    this.forceNextQ = false;
    setTimeout(() => {
        this.setState({
            activeSection: {
                status: false
            },
            sections: sections
        });
    }, 1000);
  }

  render() {
    let mainclasses = 'maincomp';
    if (this.state.activeSection.status) mainclasses += ' noscroll';
    return (
    <div className={mainclasses}>
        <div id='topcontainer' className='sections-container'>
            <div className="sections">
                {this.getSections()}
            </div>
            <div className="rightstats">
                <img src={jssvg} alt='js icon'/>
                <p className="maintitle bounceIn">You think you know JS!</p>
                <p className="result">Result {this.getResult()}</p>
                <p className="tries">Tries {this.tries}</p>
                <p className="credit">Developed by <a href="https://ashrafgabesh.com" rel="noopener noreferrer" target="_blank">Ashraf Gabesh</a> using react.js library</p>
            </div>
            {this.getUpperSection()}
        </div>
    </div>
    );
  }
}

export default Main;
