import React from 'react';
import './tile.css';

const Tile = (props) => {
    let index = props.index;
    let color = props.color;
    let clickEv = props.clickEv;
    let noca = props.numberOfCorrentAns;
    let total = props.total;
    let title = getLetters(props.title.toUpperCase());

    return (
        <div className={`section sctn${index}`} key={index} style={{background: color}} onClick={ (e) => clickEv(index, e)}>
            <div className="letters">{title}</div>
            <div className="sectionbody">
                <p className="numberofq"> {noca} - {total}</p>
            </div>
        </div>
    );
};

function getLetters(title) {
    return title.split(' ')[0].split('').map((letter, index) => {
        return <p className="letter" key={index}>{letter}</p>;
    });
}

export default Tile;